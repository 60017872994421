import React, { useState } from 'react'

const AddRoom = ({ setShowAddRoom }) => {
    const [responseMessage, setResponseMessage] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        bed_type: '',
        bed: '',
        price: '',
        image: null,
    })

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleFileChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            image: e.target.files[0], // Store the selected file
        }));
    };

    const handleAddRoom = async (e) => {
        e.preventDefault();
        setResponseMessage('');

        const form = new FormData();
        for (let key in formData) {
            form.append(key, formData[key]);
        }

        for (let pair of form.entries()) {
            console.log(pair[0], pair[1]);
        }
    
        try {
            const response = await fetch('http://localhost:3002/api/add-room', {
                method: "POST",
                body: form,
            });
            if (response.ok) {
                setResponseMessage('Your room has been added successfully.');
                setTimeout(() => {
                    setResponseMessage('');
                }, 3000);
                window.location.reload();
            } else {
                setResponseMessage("Failed to add room. Try again later.");
                setTimeout(() => {
                    setResponseMessage('');
                }, 3000);
            }
        }
        catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <>
            <section className='addroom-area' onClick={() => setShowAddRoom(false)}>
                <div className='addroom-inner col-lg-8' onClick={(e) => e.stopPropagation()}>

                    <p className='banner-subheading'>Add Room</p>


                    <form class="row g-3 p-4" onSubmit={handleAddRoom}>
                                    <div class="col-12">
                                        <input type="text" class="form-input" id="inputName" value={formData.name} name='name' onChange={handleChange} placeholder="Room Name" required />
                                    </div>
                                    <div class="col-12">
                                        <input type="text" class="form-input" id="inputRoomType" value={formData.bed_type} name='bed_type' onChange={handleChange} placeholder='Bed Type' required />
                                    </div>
                                    <div class="col-12">
                                        <input type="number" class="form-input" id="inputBed" value={formData.bed} name='bed' onChange={handleChange} placeholder='No of Beds' required />
                                    </div>
                                    <div class="col-12">
                                    <input type="number" class="form-input" id="inputPrice" value={formData.price} name='price' onChange={handleChange} placeholder='Price per Night' required />
                                    </div>
                                    <div class="col-12 text-start">
                                    <label htmlFor="Site Image" className='form-label text-muted' >Room Image</label>
                                        <input type="file" className="form-input" name='image' onChange={handleFileChange} required />
                                    </div>
                                    <div class="col-12">
                                        <button type="submit" class="banner-btn"><i class="fa-regular fa-paper-plane"></i> Submit</button>
                                    </div>
                                </form>

                </div>
            </section>

            {responseMessage && (
                <div className="response-message d-flex justify-content-between">
                    <p className='my-0 mx-auto'>{responseMessage}</p>
                    <p className='my-0 cursor-pointer' onClick={() => setResponseMessage(false)}><i class="fa-solid fa-xmark"></i></p>
                </div>)}

        </>
    )
}

export default AddRoom
