import React, { useEffect, useState } from 'react'
import SubHeader from '../general/SubHeader';

const AdminBookings = () => {
    const [booking, setBookings] = useState([]);


    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const response = await fetch('http://localhost:3002/api/get-bookings');
                const data = await response.json();
                if (response.ok) {
                    setBookings(data);
                }
                else {
                    console.error("Failed to get enquiry", data);
                }
            }
            catch (error) {
                console.error("Error fetching enquiry", error);
            }
        };
        fetchBookings();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString); // Create Date object
        return date.toLocaleDateString(); // Default format is 'MM/DD/YYYY' in the browser's locale
      };
    

    return (
        <>
            <SubHeader message="Bookings" />

            <section className='py-5 bg-light'>
                <div className='container text-center'>


                    <p className='banner-subheading'>Room Bookings</p>
                    {/* <p className='sec-heading'>Enquiries Received</p> */}

                    <div className='table-responsive mt-4'>
                        <table className='table w-100'>

                            <tr className='bg-blue text-light'>
                                <th className='px-3 py-3'>S No</th>
                                <th className='px-3 py-3'>Name</th>
                                <th className='px-3 py-3'>Email</th>
                                <th className='px-3 py-3'>Phone</th>
                                <th className='px-3 py-3'>Check In</th>
                                <th className='px-3 py-3'>Check Out</th>
                                <th className='px-3 py-3'>Room Name</th>
                                <th className='px-3 py-3'>Price per night</th>
                            </tr>

                            {booking.length > 0 ? (
                                booking.map((bookings, index) => (
                                    <tr className='bg-white border-bottom' key={bookings.id}>
                                        <td className='px-3 py-2'>{index + 1}</td>
                                        <td className='px-3 py-2'>{bookings.bookingName}</td>
                                        <td className='px-3 py-2'>{bookings.email}</td>
                                        <td className='px-3 py-2'>{bookings.phone}</td>
                                        <td className='px-3 py-2'>{formatDate(bookings.checkin)}</td>
                                        <td className='px-3 py-2'>{formatDate(bookings.checkout)}</td>
                                        <td className='px-3 py-2'>{bookings.name}</td>
                                        <td className='px-3 py-2'>{bookings.price}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr><td colSpan="8" className='px-3 py-2'>No Bookings</td></tr>
                            )}

                        </table>
                    </div>


                </div>
            </section>

        </>
    )
}

export default AdminBookings
