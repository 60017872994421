import React, { useState } from 'react'
import SubHeader from '../general/SubHeader'
import 'swiper/css';
import Highlights from '../general/Highlights';


const Bookings = ({ rooms }) => {
    const [showBookRoom, setShowBookRoom] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        checkIn: '',
        checkOut: '',
        room_name: '',
        room_id: '',
    });

    const handleShowBooking = (room) => {
        console.log(room);
        setFormData((prev) => ({ ...prev, room_name: room.name, room_id: room.id }));
        setShowBookRoom(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // handle booking
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(
                "http://localhost:3002/api/booking-details",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                }
            );

            if (response.ok) {
                setResponseMessage(
                    "Booking Details Submitted. We will revert you soon."
                );
                setTimeout(() => {
                    setResponseMessage("");
                }, 3000);
                setShowBookRoom(false); // Close the form on success
            } else {
                setResponseMessage("Booking failed. Please try again.");
                setTimeout(() => {
                    setResponseMessage("");
                }, 3000);
            }
        } catch (error) {
            setResponseMessage(
                "Error while submitting the details. Try again later."
            );
            console.error("Error while submitting booking:", error);
            setTimeout(() => {
                setResponseMessage("");
            }, 3000);
        }
    };



    return (
        <>
            <SubHeader message='Room Booking' />

            <Highlights />

            {/* room listing */}
            <section className='py-5'>
                <div className='container'>

                    <div className='row'>

                        <div className='col-lg-5 col-md-5 col-12 my-2'>
                            <p className='banner-subheading my-0'>Book Room</p>
                            <p className='sec-heading'>Well Furnished Rooms</p>
                        </div>

                        <div className='col-lg-5 col-md-7 col-12 my-2 text-secondary'>
                            <p>We offer comfortable, well-furnished rooms with essential amenities such as free Wi-Fi, air conditioning,
                                TV, and 24/7 room service, ensuring a relaxing and convenient stay for guests.</p>
                        </div>

                        {/* <div className='col-lg-2 swiper-btn-area'>
                            <span className="text-black swiper-button-prev mx-1"><i class="fa-solid fa-angle-left"></i></span>
                            <span className="text-black swiper-button-next mx-1"><i class="fa-solid fa-angle-right"></i></span>
                        </div> */}

                        {rooms.length > 0 ? (
                            rooms.map((room) => (
                                <div className='col-lg-4 col-md-6 col-12 my-2' key={room.id}>
                                    <div className='room-area'>
                                        <img src={`http://localhost:3002/uploads/${room.image}`} className='img-fluid w-100' alt='Room' />
                                        <div className='room-content'>
                                            <p className='fs-4 my-0'>{room.name}</p>
                                            <p><i class="fa-solid text-golden fa-mattress-pillow"></i> {room.bed_type} &nbsp; &nbsp;
                                                <i class="fa-solid text-golden fa-bed"></i> {room.bed} Bed &nbsp; &nbsp;
                                                <i class="fa-solid text-golden fa-tags"></i> {room.price} per night</p>
                                        </div>
                                    </div>
                                    <button className='header-btn mt-2' onClick={() => handleShowBooking(room)}><i class="fa-solid fa-calendar-check"></i> Book Now</button>&nbsp;&nbsp;
                                    <button className='header-btn mt-2' onClick={() => window.location.href= 'tel:75 5960909'}><i class="fa-solid fa-phone"></i> Call Now</button>
                                </div>
                            ))
                        ) : (
                            <p className='text-white fs-5 fw-bold text-center'>No rooms available yet</p>
                        )}

                    </div>
                    {/* row ends */}

                </div>
            </section>
            {/* room listing ends */}

            {showBookRoom && (
                <>
                    <section className='addroom-area' onClick={() => setShowBookRoom(false)}>
                        <div className='addroom-inner col-lg-8' onClick={(e) => e.stopPropagation()}>

                            <p className='banner-subheading'>Booking Details</p>


                            <form class="row g-3 p-4" onSubmit={handleSubmit}>
                                <div class="col-12">
                                    <input type="text" class="form-input" id="inputName" value={formData.name} name='name' onChange={handleChange} placeholder="Your Name" required />
                                </div>
                                <div class="col-md-6">
                                    <input type="text" class="form-input" id="inputEmail" value={formData.email} name='email' onChange={handleChange} placeholder='Email' required />
                                </div>
                                <div class="col-md-6">
                                    <input type="tel" class="form-input" maxLength={10} id="inputPhone" value={formData.phone} name='phone' onChange={handleChange} placeholder='Phone' required />
                                </div>
                                <div class="col-md-6 text-start">
                                    <label htmlFor="Check In" className='form-label text-muted' >Check In</label>
                                    <input type="date" class="form-input" id="inputCheckin" value={formData.checkIn} name='checkIn' onChange={handleChange} placeholder='Check In' required />
                                </div>
                                <div class="col-md-6 text-start">
                                    <label htmlFor="Check Out" className='form-label text-muted' >Check Out</label>
                                    <input type="date" class="form-input" id="inputCheckout" value={formData.checkOut} name='checkOut' onChange={handleChange} placeholder='Check Out' required />
                                </div>
                                <div class="col-12">
                                    <input type="text" class="form-input" id="inputRoom" value={formData.room_name} name='room_name' disabled required />
                                </div>
                                <div class="col-12">
                                    <button type="submit" class="banner-btn"><i class="fa-regular fa-paper-plane"></i> Submit</button>
                                </div>
                            </form>

                        </div>
                    </section>
                </>
            )}

            {responseMessage && (
                <div className="response-message d-flex justify-content-between">
                    <p className='my-0 mx-auto'>{responseMessage}</p>
                    <p className='my-0 cursor-pointer' onClick={() => setResponseMessage(false)}><i class="fa-solid fa-xmark"></i></p>
                </div>)}


        </>
    )
}

export default Bookings
