import React from 'react';
import AboutImg1 from '../assets/about-img1.jpg';
import AboutImg2 from '../assets/about-img2.jpg';
import ScrollAnimation from 'react-animate-on-scroll';
import Offer1 from '../assets/offer-1.jpg';
import Offer2 from '../assets/offer-2.jpg';
import Offer3 from '../assets/offer-3.jpeg';
import Offer4 from '../assets/offer-4.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import Services from '../general/Services';
import { useNavigate } from 'react-router-dom';
import Highlights from '../general/Highlights';


const Home = ({ rooms }) => {

    const navigate = useNavigate();

    return (
        <>
            {/* banner section */}
            <section className='banner-bg'>
                <div className='container'>

                    <p className='banner-subheading' style={{ color: 'var(--golden)' }}>Mahadeva Stay Homes</p>
                    <p className='banner-heading col-lg-8 col-12'>Enjoy A Luxury Experience</p>
                    <button className='banner-btn' onClick={() => navigate('/contact')}>Enquire Now</button>

                    <div className='banner-btn2 animate__animated animate__zoomIn animate__infinite'>
                        <i class="fa-solid fa-play"></i>
                    </div>

                </div>
            </section>
            {/* banner section ends */}

<Highlights />

            {/* about section */}
            <section className='my-5'>
                <div className='container'>

                    <div className='row'>

                        <div className='col-lg-6 col-12 my-2'>
                            <p className='banner-subheading my-0'>About Mahadeva</p>
                            <p className='sec-heading'>Best Hotel In Prayagraj</p>

                            <p className='fw-bold fs-5 fst-italic text-golden'>We Provide Best and Delux rooms In Your Budget</p>
                            <p className='text-muted'>Mahadeva Homes, being located in a Residential region offers close proximity not only to the premium institutes like IIIT (Prayagraj), Shambhunath Institute but additionally to the Prayagraj Railway Station & Airport.<br /><br />

                                We, at Mahadeva Homes, intend to offer a comfortable experience to our clients. With our affordable rates and quality service, Mahadeva Stay Home ensures a memorable stay, making it the ideal choice for
                                visitors looking for a home-like experience in Prayagraj.</p>
                            <button className='banner-btn mt-3' onClick={() => navigate('/about')}>About Hotel</button>

                        </div>

                        <div className='col-lg-6 col-12 my-2 row'>
                            <ScrollAnimation animateIn='fadeInRight' className='col-lg-6 col-md-6 col-sm-6 col-12 mb-2'>
                                <img src={AboutImg1} className='img-fluid w-100 h-100 rounded' alt='About' /></ScrollAnimation>
                            <ScrollAnimation animateIn='fadeInDown' className='col-lg-6 col-md-6 col-sm-6 col-12 mb-2'>
                                <img src={AboutImg2} className='img-fluid w-100 h-100 rounded' alt='About' /></ScrollAnimation>
                        </div>

                    </div>
                    {/* row ends */}

                </div>
            </section>
            {/* about section ends */}


            {/* offers section */}
            <section className='bg-light py-5'>
                <div className='container text-center'>

                    <p className='banner-subheading my-0'>Comfort & Relax</p>
                    <p className='sec-heading'>Get Exclusive Offers</p>

                    <div className='row'>

                        <div className='col-lg-3 col-md-6 col-6 my-2 position-relative'>
                            <img src={Offer1} className='img-fluid offer-img' alt='Offer' />
                            <p className='offer-text'>Stay</p>
                        </div>

                        <div className='col-lg-3 col-md-6 col-6 my-2 position-relative'>
                            <img src={Offer2} className='img-fluid offer-img' alt='Offer' />
                            <p className='offer-text'>Dining</p>
                        </div>

                        <div className='col-lg-3 col-md-6 col-6 my-2 position-relative'>
                            <img src={Offer3} className='img-fluid offer-img' alt='Offer' />
                            <p className='offer-text'>Relax</p>
                        </div>

                        <div className='col-lg-3 col-md-6 col-6 my-2 position-relative'>
                            <img src={Offer4} className='img-fluid offer-img' alt='Offer' />
                            <p className='offer-text'>Weddings</p>
                        </div>

                    </div>
                    {/* row ends */}

                </div>
            </section>
            {/* offers section ends */}


            {/* room listing */}
            <section className='py-5 bg-black'>
                <div className='container'>


                    <div className='row text-light'>

                        <div className='col-lg-5 col-md-5 col-12 my-2'>
                            <p className='banner-subheading my-0'>Mahadeva Stay Home</p>
                            <p className='sec-heading'>Rooms & Amenities</p>
                        </div>

                        <div className='col-lg-5 col-md-7 col-12 my-2 text-secondary'>
                            <p>We offer comfortable, well-furnished rooms with essential amenities such as free Wi-Fi, air conditioning,
                                24 hours electricity & water supply, power backup and more, ensuring a relaxing and convenient stay for guests.</p>
                        </div>

                        <div className='col-lg-2 swiper-btn-area'>
                            <span className="swiper-button-prev mx-1"><i class="fa-solid fa-angle-left"></i></span>
                            <span className="swiper-button-next mx-1"><i class="fa-solid fa-angle-right"></i></span>
                        </div>

                    </div>
                    {/* row ends */}


                    <Swiper spaceBetween={30} className='mt-4' slidesPerView={2} modules={[Autoplay, Navigation]} loop={true}
                        navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }} autoplay={{ delay: 2000 }}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            768: {
                                // width: 768,
                                slidesPerView: 2,
                            },
                            992: {
                                slidesPerView: 2,
                            }
                        }}>

                        {rooms.length > 0 ? (
                            rooms.map((room) => (
                                <SwiperSlide key={room.id}>
                                    <div className='room-area'>
                                        <img src={`http://localhost:3002/uploads/${room.image}`} className='img-fluid w-100' alt='Room' />
                                        <div className='room-content'>
                                            <p className='fs-4 my-0'>{room.name}</p>
                                            <p><i class="fa-solid text-golden fa-mattress-pillow"></i> {room.bed_type} &nbsp; &nbsp;
                                                <i class="fa-solid text-golden fa-bed"></i> {room.bed} Bed &nbsp; &nbsp;
                                                <i class="fa-solid text-golden fa-tags"></i> {room.price} per night</p>
                                        </div>
                                    </div>

                                </SwiperSlide>
                            ))
                        ) : (
                            <p className='text-white fs-5 fw-bold text-center'>No rooms available yet</p>
                        )}

                    </Swiper>

                </div>
            </section>
            {/* room listing ends */}


            <Services />


            {/* Reviews section */}
            <section className='review-bg'>
                <div className='container'>

                    <div id="carouselExampleIndicators" class="carousel slide col-lg-8 text-center mx-auto" data-bs-ride="carousel" data-bs-interval="2000">
                        <div class="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div class="carousel-inner py-5">
                            <div class="carousel-item active">
                                <div className='text-golden'><i class="fa-solid fa-star px-1"></i><i class="fa-solid fa-star px-1"></i><i class="fa-solid fa-star px-1"></i><i class="fa-solid fa-star px-1"></i><i class="fa-solid fa-star px-1"></i></div>
                                <p className='sec-heading fs-5 my-4'>Mahadeva Stay Home offers excellent room booking services with prompt responses and hassle-free check-in.</p>
                            </div>
                            <div class="carousel-item">
                                <div className='text-golden'><i class="fa-solid fa-star px-1"></i><i class="fa-solid fa-star px-1"></i><i class="fa-solid fa-star px-1"></i><i class="fa-solid fa-star px-1"></i><i class="fa-solid fa-star px-1"></i></div>
                                <p className='sec-heading fs-5 my-4'>Great experience! Booking was seamless, and the rooms were clean, comfortable, and affordable.</p>
                            </div>
                            <div class="carousel-item">
                                <div className='text-golden'><i class="fa-solid fa-star px-1"></i><i class="fa-solid fa-star px-1"></i><i class="fa-solid fa-star px-1"></i><i class="fa-solid fa-star px-1"></i><i class="fa-solid fa-star px-1"></i></div>
                                <p className='sec-heading fs-5 my-4'>Efficient room booking at Mahadeva Stay Home—friendly staff and well-maintained rooms. Highly recommended!</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {/* Reviews section ends */}


        </>
    )
}

export default Home
