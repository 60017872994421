import React, { useState } from 'react'
import Logo from '../assets/logo.jpeg';
import { NavLink, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AddRoom from '../components/AddRoom';

const Header = ({ isLoggedIn }) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const [showAddRoom, setShowAddRoom] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn', 'true');
        navigate('/');
        setShowSidebar(false);
        window.location.reload();
    }


    return (
        <>
            <section className='header-bg py-2'>
                <div className='container d-flex justify-content-between'>

                    <div><i className="fa-solid fa-phone"></i> +752 596 0909<i className="fa-solid fa-envelope ms-3"></i> mahadevahomes.in@gmail.com</div>

                    <div><i class="fa-brands cursor-pointer me-3 fa-instagram"></i>
                        <i class="fa-brands cursor-pointer me-3 fa-facebook-f"></i>
                        <i class="fa-brands cursor-pointer me-3 fa-youtube"></i></div>
                </div>
                {/* flex ends */}
            </section>

            <section className='py-2 sticky-top bg-white'>
                <div className='container d-flex justify-content-between'>

                    <img src={Logo} className='header-logo my-lg-1 my-md-1 my-sm-1 my-2' />
                    {/* <p className='sec-heading fs-5 mt-2 mb-0 fw-bold fst-italic'>Mahadeva Stay Home</p> */}

                    {/* <img src={Logo} className='img-fluid' style={{ height: '2em' }} /> */}

                    <div className='d-flex my-2'>
                        <p className='pe-3 cursor-pointer fs-5 my-2' onClick={() => setShowSidebar(true)}><i className="fa-solid fa-bars"></i></p>

                        <button className='header-btn' onClick={isLoggedIn ? () => setShowAddRoom(true) : () => navigate('/room-booking')}>
                            <i className="fa-solid fa-calendar-check"></i>
                            {isLoggedIn ? ' Add Room' : ' Book Now'}
                        </button>

                    </div>

                </div>
            </section>
            {/* navbar ends */}

            {showSidebar && !isLoggedIn && (
                <section className='sidebar-area animate__animated animate__fadeInLeft' onClick={() => setShowSidebar(false)}>
                    <div className='sidebar-inner animate__animated animate__fadeInLeft' onClick={(e) => e.stopPropagation()}>
                        <span onClick={() => setShowSidebar(false)}><i class="fa-solid fa-xmark close-btn"></i></span><br />
                        <img src={Logo} className='img-fluid my-5' alt='Logo' /><br /><br />
                        <NavLink className='nav-text' activeClassName="active" onClick={() => setShowSidebar(false)} to='/'>Home</NavLink><br /><br />
                        <NavLink className='nav-text' activeClassName="active" onClick={() => setShowSidebar(false)} to='/about'>About</NavLink><br /><br />
                        <NavLink className='nav-text' activeClassName="active" onClick={() => setShowSidebar(false)} to='/room-booking'>Room Booking</NavLink><br /><br />
                        <NavLink className='nav-text' activeClassName="active" onClick={() => setShowSidebar(false)} to='/contact'>Contact</NavLink><br /><br /><br /><br />

                        <Link className="header-btn rounded-pill py-3" to='tel:7525960909'><i className="fa-solid fa-phone"></i> Call +752 596 0909</Link>

                    </div>
                </section>
            )}

            {showSidebar && isLoggedIn && (
                <section className='sidebar-area animate__animated animate__fadeInLeft' onClick={() => setShowSidebar(false)}>
                    <div className='sidebar-inner animate__animated animate__fadeInLeft' onClick={(e) => e.stopPropagation()}>
                        <span onClick={() => setShowSidebar(false)}><i class="fa-solid fa-xmark close-btn"></i></span><br />
                        <img src={Logo} className='img-fluid my-5' alt='Logo' /><br /><br />
                        <NavLink className='nav-text' activeClassName="active" onClick={() => setShowSidebar(false)} to='/admin/dashboard'>Dashboard</NavLink><br /><br />
                        <NavLink className='nav-text' activeClassName="active" onClick={() => setShowSidebar(false)} to='/admin/bookings'>Bookings</NavLink><br /><br />
                        <NavLink className='nav-text' activeClassName="active" onClick={() => setShowSidebar(false)} to='/admin/enquiries'>Form Enquiries</NavLink><br /><br />
                        <Link className='nav-text' activeClassName="active" onClick={handleLogout}>Logout</Link><br /><br /><br /><br />

                    </div>
                </section>
            )}

            {showAddRoom && <AddRoom setShowAddRoom={setShowAddRoom} />}

        </>
    )
}

export default Header
