import React from 'react'

const SubHeader = ({ message }) => {
    return (
        <>
            <section className='subheader-bg'>
                <div className='container'>
                    <p className='banner-subheading' style={{color: 'var(--golden)'}}>Mahadeva Stay Home</p>
                    <p className='banner-heading col-lg-8 col-12'>{message}</p>
                </div>
            </section>
        </>
    )
}

export default SubHeader
