import React, { useState, useEffect } from 'react'
import 'animate.css';
import 'animate.css/animate.compat.css';
import ScrollTop from './general/ScrollTop';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PageNotFound from '../src/components/PageNotFound';
import Header from './general/Header';
import Footer from './general/Footer';
import Home from '../src/components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Enquiries from './components/Enquiries';
import Bookings from './components/Bookings';
import AdminBookings from './components/AdminBookings';

const App = () => {
  const [rooms, setRooms] = useState([]);
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await fetch('http://localhost:3002/api/get-room');
        const data = await response.json();
        if (response.ok) {
          setRooms(data);
        }
        else {
          console.error("Failed to get rooms", data);
        }
      }
      catch (error) {
        console.error('Error while fetching rooms:', error);
      }
    }
    fetchRooms();
  }, [])


  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        <Header isLoggedIn={isLoggedIn} />

        {!isLoggedIn && (
          <>
            <div className='main-content'>
              <Routes>
                <Route exact path='/' element={<Home rooms={rooms} />} />
                <Route exact path='/home' element={<Home rooms={rooms} />} />
                <Route exact path='/about' element={<About />} />
                <Route exact path='/room-booking' element={<Bookings rooms={rooms} />} />
                <Route exact path='/contact' element={<Contact />} />
                <Route exact path='/login' element={<Login />} />
                <Route exact path='*' element={<PageNotFound />} />

              </Routes>
            </div>
            <Footer />
          </>
        )}


        {isLoggedIn && (
          <>
            <div className='main-content'>
              <Routes>
                <Route exact path='/' element={<Dashboard />} />
                <Route exact path='/admin/dashboard' element={<Dashboard rooms={rooms} setRooms={setRooms} />} />
                <Route exact path='/admin/bookings' element={<AdminBookings />} />
                <Route exact path='/admin/enquiries' element={<Enquiries />} />
                <Route exact path='*' element={<PageNotFound />} />

              </Routes>
            </div>
          </>
        )}

      </BrowserRouter>
    </>
  )
}

export default App
