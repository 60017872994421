import React from 'react'
import LostImg from '../assets/pagenotfound.gif';
import { useNavigate } from 'react-router-dom';


const PageNotFound = () => {

  const navigate = useNavigate();

  return (
    <>
    <section className='container text-center my-5'>
      <img src={LostImg} className='img-fluid' alt='Page not found' />
      <p className='fw-semibold'>Oops Page not Found! Looks you are lost somewhere. Let's get you back to the site now.</p>
      <button className='btn btn-success mt-3 animate__animated animate__pulse animate__infinite' onClick={() => navigate('/')}>Click Here</button>
    </section>
    </>
  )
}

export default PageNotFound;
