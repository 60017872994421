import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/logo.png';
import ScrollToTop from 'react-scroll-to-top';

const Footer = () => {
    return (
        <>

<ScrollToTop smooth className='scroll-to-top' viewBox="0 0 448 512"
        svgPath="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" />

            <footer className='pt-5 pb-1 bg-black'>
                <div className='container'>

                    <div className='row text-secondary'>

                        <div className='col-lg-4 col-md-4 col-12 my-2'>
                        <img src={Logo} className='img-fluid' style={{height: '4em'}} />
                        <p className='my-3'>With our affordable rates and quality service, Mahadeva Stay Home ensures a memorable stay, making it
                                the ideal choice for visitors looking for a home-like experience in Prayagraj.</p>
                            <i class="fa-brands footer-icon me-3 fa-instagram"></i>
                            <i class="fa-brands footer-icon me-3 fa-facebook"></i>
                            <i class="fa-brands footer-icon me-3 fa-youtube"></i>
                        </div>

                        <div className='col-lg-4 col-md-4 col-6 my-2'>
                            <p className='sec-heading fs-4 text-light text-capitalize'>Important</p>
                            <Link className='text-decoration-none text-secondary fw-semibold' to='/'>Home</Link><br /><br />
                            <Link className='text-decoration-none text-secondary fw-semibold' to='/about'>About</Link><br /><br />
                            <Link className='text-decoration-none text-secondary fw-semibold' to='/room-booking'>Room Booking</Link><br /><br />
                            <Link className='text-decoration-none text-secondary fw-semibold' to='/contact'>Contact</Link>
                        </div>

                        <div className='col-lg-4 col-md-4 col-6 my-2'>
                            <p className='sec-heading fs-4 text-light text-capitalize'>Contact</p>
                            <Link className='text-decoration-none text-secondary' to='https://maps.app.goo.gl/BASQ3HY3c5KQBFFa8' target='_blank'><i class="fa-solid fa-map-location-dot"></i> 47, Devprayagam Phase- 2, Near, IIIT Chauraha, Jhalwa, Prayagraj, Uttar Pradesh 211012</Link><br /><br />
                                <Link className='text-decoration-none text-secondary' to='tel:7525960909'><i class="fa-solid fa-phone"></i> +752 596 0909</Link><br /><br />
                                <Link className='text-decoration-none text-secondary' to='mailto:mahadevahomes.in@gmail.com'><i class="fa-solid fa-envelope"></i> mahadevahomes.in@gmail.com</Link>
                        </div>


                    </div>
                    {/* row ends */}

                </div>

                <hr className='text-light' />
                <p className='px-4 text-light text-end my-4'>Copyright &copy; Mahadeva Stay Home. All Rights Reserved. Developed By Dectwin Services Pvt Ltd</p>

            </footer>
        </>
    )
}

export default Footer
