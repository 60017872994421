import React, { useState, useRef } from 'react'
import axios from 'axios';
import SubHeader from '../general/SubHeader'
import LocationImg from '../assets/location.jpg';
import CallImg from '../assets/calling.jpg';
import emailjs from '@emailjs/browser';


const Contact = () => {
    const [responseMessage, setResponseMessage] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const form = useRef();

       // Handle form submission
       const handleSubmit = async (e) => {
        e.preventDefault();
        setResponseMessage(''); // Clear previous response message

        emailjs
        .sendForm('mahadeva_stay', 'template_contact', form.current, {
          publicKey: 'nMCu9RFv40f7VIq5S',
        })
        .then(
          () => {
            console.log('Form Submitted!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );

        try {
            const response = await axios.post('http://localhost:3002/api/contact', formData);

            if (response.status === 200) {
                setResponseMessage('Thank you for getting in touch! We will respond you soon.');

                setTimeout(() => {
                    setResponseMessage('');
                }, 3000);
            }
        } catch (error) {
            // Handle error response
            setResponseMessage('Error submitting form. Please try again later.');
            setTimeout(() => {
                setResponseMessage('');
            }, 3000);
        }
    };




    return (
        <>
            <SubHeader message="Contact" />

            <section className='py-5 bg-light'>
                <div className='container'>
                    <div className='row justify-content-between'>

                        <div className='col-lg-7 col-12 my-2'>
                            <div className='row mb-4'>

                                <div class="col-lg-6 col-md-6 col-12 my-2">
                                    <div className='flip-card'>
                                        <div class="flip-card-inner">
                                            <div class="flip-card-front">
                                                <i class="fa-solid fa-location-dot text-golden fs-1"></i>
                                                <p className='sec-heading fs-5 text-capitalize mt-3'>Mahadeva Stay Home</p>
                                                <p className='text-muted'>47, Devprayagam Phase- 2, Near, IIIT Chauraha, Jhalwa, Prayagraj, Uttar Pradesh 211012</p>
                                            </div>
                                            <div class="flip-card-back">
                                                <img src={LocationImg} className='rounded-2 w-100 h-100' alt='location' />
                                                <button className='flip-btn' onClick={() => window.open('https://maps.app.goo.gl/BASQ3HY3c5KQBFFa8')}>View Map</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-lg-6 col-md-6 col-12 my-2">
                                    <div className='flip-card'>
                                        <div class="flip-card-inner">
                                            <div class="flip-card-front">
                                                <i class="fa-solid fa-phone text-golden fs-1"></i>
                                                <p className='sec-heading fs-5 text-capitalize mt-3'>Talk with us</p>
                                                <p className='text-muted'>Phone: +752 596 0909</p>
                                            </div>
                                            <div class="flip-card-back">
                                                <img src={CallImg} className='rounded-2 w-100 h-100' alt='location' />
                                                <button className='flip-btn' onClick={() => window.location.href ='tel:7525960909'}>Talk Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* inner row ends */}

                            <div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14412.429787413432!2d81.75635075209347!3d25.43467109093813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399accad36e1800f%3A0x3453858edd2b7fe2!2sMahadeva%20Homes%20Guest%20House!5e0!3m2!1sen!2sin!4v1736749011254!5m2!1sen!2sin"
                                    className='w-100 rounded-2' height='400' style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                        </div>
                        {/* left ends */}

                        <div className='col-lg-4 col-12'>
                            <div className='contact-form-area'>
                                <p className='sec-heading text-capitalize fs-5 bg-golden p-4 rounded-top-2'>Get In Touch !</p>


                                <form class="row g-3 p-4" ref={form} onSubmit={handleSubmit}>
                                    <div class="col-12">
                                        <input type="text" class="form-input" id="inputName" value={formData.name} name='name' onChange={handleChange} placeholder="Name" required />
                                    </div>
                                    <div class="col-12">
                                        <input type="email" class="form-input" id="inputEmail4" value={formData.email} name='email' onChange={handleChange} placeholder='Email' required />
                                    </div>
                                    <div class="col-12">
                                        <input type="tel" class="form-input" id="inputPhone4" value={formData.phone} name='phone' onChange={handleChange} placeholder='Phone' required />
                                    </div>
                                    <div class="col-12">
                                        <textarea type="text" class="form-input" id="inputMessage4" value={formData.message} name='message' onChange={handleChange} placeholder='Message' required></textarea>
                                    </div>
                                    <div class="col-12">
                                        <button type="submit" class="banner-btn"><i class="fa-regular fa-paper-plane"></i> Submit</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                        {/* right ends */}

                    </div>
                    {/* row ends */}
                </div>
            </section>

            {responseMessage && (
                <div className="response-message d-flex justify-content-between">
                    <p className='my-0 mx-auto'>{responseMessage}</p>
                    <p className='my-0 cursor-pointer' onClick={() => setResponseMessage(false)}><i class="fa-solid fa-xmark"></i></p>
                </div>)}

        </>
    )
}

export default Contact
