import React, { useEffect, useState } from 'react'
import SubHeader from '../general/SubHeader';

const Enquiries = () => {
    const [enquiry, setEnquiry] = useState([]);


    useEffect(() => {
        const fetchEnquiry = async () => {
            try {
                const response = await fetch('http://localhost:3002/api/get-enquiry');
                const data = await response.json();
                if (response.ok) {
                    setEnquiry(data);
                }
                else {
                    console.error("Failed to get enquiry", data);
                }
            }
            catch (error) {
                console.error("Error fetching enquiry", error);
            }
        };
        fetchEnquiry();
    }, []);


    return (
        <>
            <SubHeader message="Enquiry" />

            <section className='py-5 bg-light'>
                <div className='container text-center'>


                    <p className='banner-subheading'>Contact Form Enquiries</p>
                    {/* <p className='sec-heading'>Enquiries Received</p> */}

                    <div className='table-responsive mt-4'>
                        <table className='table w-100'>

                            <tr className='bg-blue text-light'>
                                <th className='px-3 py-3'>S No</th>
                                <th className='px-3 py-3'>Name</th>
                                <th className='px-3 py-3'>Email</th>
                                <th className='px-3 py-3'>Phone</th>
                                <th className='px-3 py-3'>Message</th>
                            </tr>

                            {enquiry.length > 0 ? (
                                enquiry.map((enquiries, index) => (
                                    <tr className='bg-white border-bottom' key={enquiries.id}>
                                        <td className='px-3 py-2'>{index + 1}</td>
                                        <td className='px-3 py-2'>{enquiries.name}</td>
                                        <td className='px-3 py-2'>{enquiries.email}</td>
                                        <td className='px-3 py-2'>{enquiries.phone}</td>
                                        <td className='px-3 py-2'>{enquiries.message}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr><td colSpan="5" className='px-3 py-2'>No Enquiry Submission</td></tr>
                            )}

                        </table>
                    </div>


                </div>
            </section>

        </>
    )
}

export default Enquiries
