import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

const Services = () => {
  return (
    <>
                  {/* services section */}
                  <section className='py-5 bg-light'>
                <div className='container'>

                    <div className='row'>

                        <div className='col-lg-3 col-12 my-2 align-content-center'>
                            <p className='banner-subheading my-0'>Services</p>
                            <p className='sec-heading'>Get Benefits</p>
                            <p className='text-muted'>Enjoy exclusive benefits like comfort, relaxation, dining, and special offers during your stay.</p>
                        </div>

                        <ScrollAnimation animateIn='zoomIn' className='col-lg-9 col-12 my-2'>
                            <div className='row'>

                                <div className='col-lg-4 col-md-4 col-12 my-2'>
                                    <div className='services-area'>
                                        <i class="fa-solid fa-cart-flatbed-suitcase text-golden"></i>
                                        <p className='fs-5'>Budget Rooms</p>
                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-4 col-12 my-2'>
                                    <div className='services-area'>
                                        <i class="fa-solid fa-square-parking text-golden"></i>
                                        <p className='fs-5'>Parking Space</p>
                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-4 col-12 my-2'>
                                    <div className='services-area'>
                                        <i class="fa-solid fa-soap text-golden"></i>
                                        <p className='fs-5'>Room Service</p>
                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-4 col-12 my-2'>
                                    <div className='services-area'>
                                        <i class="fa-solid fa-wifi text-golden"></i>
                                        <p className='fs-5'>Wifi</p>
                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-4 col-12 my-2'>
                                    <div className='services-area'>
                                        <i class="fa-solid fa-plug-circle-check text-golden"></i>
                                        <p className='fs-5'>Power Backup</p>
                                    </div>
                                </div>

                                <div className='col-lg-4 col-md-4 col-12 my-2'>
                                    <div className='services-area'>
                                        <i class="fa-solid fa-champagne-glasses text-golden"></i>
                                        <p className='fs-5'>Restaurat Tie Up</p>
                                    </div>
                                </div>

                            </div>
                            {/* inner row ends */}
                        </ScrollAnimation>


                    </div>
                    {/* row ends */}

                </div>
            </section>
            {/* services section ends */}
    </>
  )
}

export default Services
