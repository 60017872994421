import React from 'react'

const Highlights = () => {
    return (
        <>
            <section className='highlight-bg'>
                <div className='container pt-5 px-4 text-center'>

                    <p className='banner-subheading'>Highlights</p>

                    <div className='row row-cols-lg-5 row-cols-md-3 row-cols-sm-3 row-cols-2 justify-content-center mt-4'>

                        <div className='col my-2'>
                                <i class="fa-solid fa-cart-flatbed-suitcase text-golden fs-2"></i>
                                <p className='fs-6 text-muted mt-2'>Budget Rooms<br />AC / Non AC</p>
                        </div>

                        <div className='col my-2'>
                                <i class="fa-solid fa-plane-departure text-golden fs-2"></i>
                                <p className='fs-6 text-muted mt-2'>Prayagraj Airport<br />4.3 km</p>
                        </div>

                        <div className='col my-2'>
                                <i class="fa-solid fa-train text-golden fs-2"></i>
                                <p className='fs-6 text-muted mt-2'>Prayagraj Junction<br />6 km</p>
                        </div>

                        <div className='col my-2'>
                                <i class="fa-solid fa-building-columns text-golden fs-2"></i>
                                <p className='fs-6 text-muted mt-2'>IIT, Prayagraj<br />1 km</p>
                        </div>

                        <div className='col my-2'>
                                <i class="fa-solid fa-street-view text-golden fs-2"></i>
                                <p className='fs-6 text-muted mt-2'>IIT Chauraha<br />400m</p>
                        </div>


                    </div>
                    {/* row ends */}

                </div>
            </section>
        </>
    )
}

export default Highlights
