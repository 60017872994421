import React from 'react'
import SubHeader from '../general/SubHeader'
import Services from '../general/Services';
import AboutImg1 from '../assets/about-img1.jpg';
import AboutImg2 from '../assets/about-img2.jpg';

const About = () => {
  return (
    <>
      <SubHeader message="About" />

      {/* about section */}
      <section className='my-5'>
        <div className='container'>

          <div className='row'>

            <div className='col-lg-6 col-12 my-2'>
              <p className='banner-subheading my-0'>About Mahadeva</p>
              <p className='sec-heading'>Best Hotel In Prayagraj</p>

              <p className='fw-bold fs-5 fst-italic text-golden'>We Provide Best and Delux rooms In Your Budget</p>
              <p className='text-muted'>Mahadeva Stay Homes, being located in a Residential region offers close proximity not only to the premium institutes like IIIT (Prayagraj), Shambhunath Institute but additionally to the Prayagraj Railway Station & Airport.<br /><br />

                We, at Mahadeva, intend to offer a comfortable experience to our clients. Offering a perfect blend of comfort and luxury. Located in a prime area, Mahadeva provides spacious rooms with modern amenities,
                excellent hospitality, and a warm atmosphere for both business and leisure travelers.<br /><br />
                With our affordable rates and quality service, we ensure a memorable stay, making it the ideal choice for
                visitors looking for a home-like experience in Prayagraj.</p>

            </div>

            <div className='col-lg-6 col-12 my-2 row'>
              <div className='col-lg-6 col-md-6 col-sm-6 col-12 mb-2'>
                <img src={AboutImg1} className='img-fluid w-100 h-100 rounded' alt='About' /></div>
              <div className='col-lg-6 col-md-6 col-sm-6 col-12 mb-2'>
                <img src={AboutImg2} className='img-fluid w-100 h-100 rounded' alt='About' /></div>
            </div>

          </div>
          {/* row ends */}

        </div>
      </section>
      {/* about section ends */}

      <Services />
    </>
  )
}

export default About
