import React, { useState } from 'react'
import SubHeader from '../general/SubHeader';

const Dashboard = ({rooms, setRooms}) => {
  const [responseMessage, setResponseMessage] = useState('');

  const handleDeleteRoom = async (roomId) => {
    try {
      const response = await fetch(`http://localhost:3002/api/delete-room?roomId=${roomId}`, {
        method: "DELETE"
      });

      if (!response.ok) {
        throw new Error('Failed to delete room');
      }
      setRooms(prevRoom => prevRoom.filter(r => r.id !== roomId));
      setResponseMessage("Room Deleted Successfully");
      setTimeout(() => {
        setResponseMessage('');
      }, 2000);
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <>
      <SubHeader message="Dashboard" />

      <section className='py-5 bg-light'>
        <div className='container'>

          <div className='row'>

            <div className='col-lg-3 col-md-6 col-12 my-lg-5 py-lg-5 my-2'>
              <p className='banner-subheading my-0'>My Rooms</p>
              <p className='sec-heading'>Check Listing</p>
              <p className='text-muted'>Look at the rooms you have added, and manage the listings by adding more or deleting rooms.</p>
            </div>

            <div className='col-lg-9 col-12 my-2'>
              <div className='row'>

                {rooms.length > 0 ? (
                  rooms.map((room) => (
                    <div className='col-lg-6 col-md-6 my-2' key={room.id}>
                      <div className='room-area'>
                        <img src={`http://localhost:3002/uploads/${room.image}`} className='img-fluid w-100' alt='Room' />
                        <div className='room-content'>
                          <p className='fs-4 my-0'>{room.name}</p>
                          <p><i class="fa-solid text-golden fa-mattress-pillow"></i> {room.bed_type} &nbsp; &nbsp;
                            <i class="fa-solid text-golden fa-bed"></i> {room.bed} Bed &nbsp; &nbsp;
                            <i class="fa-solid text-golden fa-tags"></i> {room.price} per night</p>
                        </div>
                      </div>
                      <button className='header-btn mt-2' onClick={() => handleDeleteRoom(room.id)}>Delete Room ❌</button>

                    </div>
                  ))
                ) : (
                  <p>No rooms available yet</p>
                )}

              </div>
              {/* inner row ends */}
            </div>


          </div>
          {/* row ends */}


        </div>
      </section>
      {responseMessage && (
        <div className="response-message d-flex justify-content-between">
          <p className='my-0 mx-auto'>{responseMessage}</p>
          <p className='my-0 cursor-pointer' onClick={() => setResponseMessage(false)}><i class="fa-solid fa-xmark"></i></p>
        </div>)}

    </>
  )
}

export default Dashboard
