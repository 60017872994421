import React, { useState } from 'react'
import SubHeader from '../general/SubHeader';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        if (username === 'adminmahadeva' && password === 'mahadeva@stay') {
            // If they match, navigate to the dashboard
            localStorage.setItem('username', username);
            localStorage.setItem('isLoggedIn', 'true');
            navigate('/admin/dashboard');
            window.location.reload();
        } else {
            // Optionally handle invalid login
            alert('Invalid username or password');
        }
    }


    return (
        <>
            <SubHeader message='Login' />

            <section className='py-5 bg-light px-3'>
                <div className='container text-center bg-white rounded-2 py-5 px-4 col-lg-5 col-md-8 col-12'>

                    <p className='banner-subheading'>Admin Login</p>

                    <form class="row g-3 my-4" onSubmit={handleLogin}>
                        <div class="col-12">
                            <input type="text" class="form-input" id="inputText4" value={username} onChange={(e) => setUsername(e.target.value)} placeholder='Username' />
                        </div>
                        <div class="col-12">
                            <input type="password" class="form-input" id="inputPassword4" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' />
                        </div>
                        <div class="col-12">
                            <button type="submit" class="banner-btn rounded-pill w-100 mt-3">Login</button>
                        </div>
                    </form>

                </div>
            </section>

        </>
    )
}

export default Login
